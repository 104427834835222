import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a239aed2 = () => interopDefault(import('../pages/account-requests/index.vue' /* webpackChunkName: "pages/account-requests/index" */))
const _1d45c5ca = () => interopDefault(import('../pages/ai-reports.vue' /* webpackChunkName: "pages/ai-reports" */))
const _7941f862 = () => interopDefault(import('../pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _36f36512 = () => interopDefault(import('../pages/boost-all.vue' /* webpackChunkName: "pages/boost-all" */))
const _7b2bdae6 = () => interopDefault(import('../pages/bug-reports.vue' /* webpackChunkName: "pages/bug-reports" */))
const _9fa67ed2 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _9120d3e0 = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _3c571afe = () => interopDefault(import('../pages/feedback-funnel/index.vue' /* webpackChunkName: "pages/feedback-funnel/index" */))
const _8b3b5210 = () => interopDefault(import('../pages/ht-alerts.vue' /* webpackChunkName: "pages/ht-alerts" */))
const _091c6d99 = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _13dec0d6 = () => interopDefault(import('../pages/journal-edits/index.vue' /* webpackChunkName: "pages/journal-edits/index" */))
const _32fce5de = () => interopDefault(import('../pages/lock-logs.vue' /* webpackChunkName: "pages/lock-logs" */))
const _030c1f69 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _70d8aa7c = () => interopDefault(import('../pages/manager-stats/index.vue' /* webpackChunkName: "pages/manager-stats/index" */))
const _45e673a0 = () => interopDefault(import('../pages/manuals/index.vue' /* webpackChunkName: "pages/manuals/index" */))
const _0cc063f9 = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _7839aafc = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _d0adf73c = () => interopDefault(import('../pages/missed-payments/index.vue' /* webpackChunkName: "pages/missed-payments/index" */))
const _38339d61 = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _a2d84574 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _a308f530 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _8e30ade8 = () => interopDefault(import('../pages/operator-details/index.vue' /* webpackChunkName: "pages/operator-details/index" */))
const _2366244e = () => interopDefault(import('../pages/operator-traffic-dashboard/index.vue' /* webpackChunkName: "pages/operator-traffic-dashboard/index" */))
const _78b7a103 = () => interopDefault(import('../pages/payment-updates.vue' /* webpackChunkName: "pages/payment-updates" */))
const _27859241 = () => interopDefault(import('../pages/pl-stats/index.vue' /* webpackChunkName: "pages/pl-stats/index" */))
const _6baebfa9 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _8aaefcc6 = () => interopDefault(import('../pages/profiles/index.vue' /* webpackChunkName: "pages/profiles/index" */))
const _382366da = () => interopDefault(import('../pages/rejection-categories/index.vue' /* webpackChunkName: "pages/rejection-categories/index" */))
const _2e005e96 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _9460063c = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _757595a6 = () => interopDefault(import('../pages/top-triggers/index.vue' /* webpackChunkName: "pages/top-triggers/index" */))
const _2ade1d12 = () => interopDefault(import('../pages/traffic-stats/index.vue' /* webpackChunkName: "pages/traffic-stats/index" */))
const _0fd633a4 = () => interopDefault(import('../pages/triggers/index.vue' /* webpackChunkName: "pages/triggers/index" */))
const _00ad370e = () => interopDefault(import('../pages/update-stats/index.vue' /* webpackChunkName: "pages/update-stats/index" */))
const _ca739206 = () => interopDefault(import('../pages/wink-replies/index.vue' /* webpackChunkName: "pages/wink-replies/index" */))
const _042c697e = () => interopDefault(import('../pages/manuals/new.vue' /* webpackChunkName: "pages/manuals/new" */))
const _425fbf70 = () => interopDefault(import('../pages/my-team/add.vue' /* webpackChunkName: "pages/my-team/add" */))
const _2afe31e6 = () => interopDefault(import('../pages/my-team/pending.vue' /* webpackChunkName: "pages/my-team/pending" */))
const _4b0d6d5c = () => interopDefault(import('../pages/news/CreateNewsModal.vue' /* webpackChunkName: "pages/news/CreateNewsModal" */))
const _e8fb3bf8 = () => interopDefault(import('../pages/news/EditNewsModal.vue' /* webpackChunkName: "pages/news/EditNewsModal" */))
const _683b006f = () => interopDefault(import('../pages/news/SeenByModal.vue' /* webpackChunkName: "pages/news/SeenByModal" */))
const _7bc8067e = () => interopDefault(import('../pages/profiles/create.vue' /* webpackChunkName: "pages/profiles/create" */))
const _386a11bc = () => interopDefault(import('../pages/profiles/pending.vue' /* webpackChunkName: "pages/profiles/pending" */))
const _96174966 = () => interopDefault(import('../pages/profiles/update-mixin.js' /* webpackChunkName: "pages/profiles/update-mixin" */))
const _45722ca1 = () => interopDefault(import('../pages/rejection-categories/new.vue' /* webpackChunkName: "pages/rejection-categories/new" */))
const _754aca39 = () => interopDefault(import('../pages/triggers/manage.vue' /* webpackChunkName: "pages/triggers/manage" */))
const _77b07b73 = () => interopDefault(import('../pages/triggers/pending.vue' /* webpackChunkName: "pages/triggers/pending" */))
const _22ee0453 = () => interopDefault(import('../pages/update-stats/AddNewFeature.vue' /* webpackChunkName: "pages/update-stats/AddNewFeature" */))
const _393e5aca = () => interopDefault(import('../pages/account-requests/_request.vue' /* webpackChunkName: "pages/account-requests/_request" */))
const _a377ff92 = () => interopDefault(import('../pages/activity-logs/_type.vue' /* webpackChunkName: "pages/activity-logs/_type" */))
const _849fd30a = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _165c4b95 = () => interopDefault(import('../pages/my-team/_operator/index.vue' /* webpackChunkName: "pages/my-team/_operator/index" */))
const _b5ecf1cc = () => interopDefault(import('../pages/profile-duplicates/_country.vue' /* webpackChunkName: "pages/profile-duplicates/_country" */))
const _253e2532 = () => interopDefault(import('../pages/profiles/_profile/index.vue' /* webpackChunkName: "pages/profiles/_profile/index" */))
const _f41572f0 = () => interopDefault(import('../pages/profiles/_profileold.vue' /* webpackChunkName: "pages/profiles/_profileold" */))
const _299b404d = () => interopDefault(import('../pages/triggers/_trigger.vue' /* webpackChunkName: "pages/triggers/_trigger" */))
const _65e4ddd2 = () => interopDefault(import('../pages/my-team/_operator/edit.vue' /* webpackChunkName: "pages/my-team/_operator/edit" */))
const _4eabfa58 = () => interopDefault(import('../pages/my-team/_operator/lock-logs.vue' /* webpackChunkName: "pages/my-team/_operator/lock-logs" */))
const _3b35bfb1 = () => interopDefault(import('../pages/my-team/_operator/message-list.vue' /* webpackChunkName: "pages/my-team/_operator/message-list" */))
const _1c142762 = () => interopDefault(import('../pages/my-team/_operator/profile-list.vue' /* webpackChunkName: "pages/my-team/_operator/profile-list" */))
const _61a44120 = () => interopDefault(import('../pages/my-team/_operator/trigger-list.vue' /* webpackChunkName: "pages/my-team/_operator/trigger-list" */))
const _3b6868d1 = () => interopDefault(import('../pages/profiles/_profile/photos.vue' /* webpackChunkName: "pages/profiles/_profile/photos" */))
const _39647c52 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-requests",
    component: _a239aed2,
    name: "account-requests"
  }, {
    path: "/ai-reports",
    component: _1d45c5ca,
    name: "ai-reports"
  }, {
    path: "/bonus",
    component: _7941f862,
    name: "bonus"
  }, {
    path: "/boost-all",
    component: _36f36512,
    name: "boost-all"
  }, {
    path: "/bug-reports",
    component: _7b2bdae6,
    name: "bug-reports"
  }, {
    path: "/dashboard",
    component: _9fa67ed2,
    name: "dashboard"
  }, {
    path: "/faqs",
    component: _9120d3e0,
    name: "faqs"
  }, {
    path: "/feedback-funnel",
    component: _3c571afe,
    name: "feedback-funnel"
  }, {
    path: "/ht-alerts",
    component: _8b3b5210,
    name: "ht-alerts"
  }, {
    path: "/invoices",
    component: _091c6d99,
    name: "invoices"
  }, {
    path: "/journal-edits",
    component: _13dec0d6,
    name: "journal-edits"
  }, {
    path: "/lock-logs",
    component: _32fce5de,
    name: "lock-logs"
  }, {
    path: "/login",
    component: _030c1f69,
    name: "login"
  }, {
    path: "/manager-stats",
    component: _70d8aa7c,
    name: "manager-stats"
  }, {
    path: "/manuals",
    component: _45e673a0,
    name: "manuals"
  }, {
    path: "/message-reports",
    component: _0cc063f9,
    name: "message-reports"
  }, {
    path: "/messages",
    component: _7839aafc,
    name: "messages"
  }, {
    path: "/missed-payments",
    component: _d0adf73c,
    name: "missed-payments"
  }, {
    path: "/my-team",
    component: _38339d61,
    name: "my-team"
  }, {
    path: "/news",
    component: _a2d84574,
    name: "news"
  }, {
    path: "/notifications",
    component: _a308f530,
    name: "notifications"
  }, {
    path: "/operator-details",
    component: _8e30ade8,
    name: "operator-details"
  }, {
    path: "/operator-traffic-dashboard",
    component: _2366244e,
    name: "operator-traffic-dashboard"
  }, {
    path: "/payment-updates",
    component: _78b7a103,
    name: "payment-updates"
  }, {
    path: "/pl-stats",
    component: _27859241,
    name: "pl-stats"
  }, {
    path: "/profile",
    component: _6baebfa9,
    name: "profile"
  }, {
    path: "/profiles",
    component: _8aaefcc6,
    name: "profiles"
  }, {
    path: "/rejection-categories",
    component: _382366da,
    name: "rejection-categories"
  }, {
    path: "/settings",
    component: _2e005e96,
    name: "settings"
  }, {
    path: "/stats",
    component: _9460063c,
    name: "stats"
  }, {
    path: "/top-triggers",
    component: _757595a6,
    name: "top-triggers"
  }, {
    path: "/traffic-stats",
    component: _2ade1d12,
    name: "traffic-stats"
  }, {
    path: "/triggers",
    component: _0fd633a4,
    name: "triggers"
  }, {
    path: "/update-stats",
    component: _00ad370e,
    name: "update-stats"
  }, {
    path: "/wink-replies",
    component: _ca739206,
    name: "wink-replies"
  }, {
    path: "/manuals/new",
    component: _042c697e,
    name: "manuals-new"
  }, {
    path: "/my-team/add",
    component: _425fbf70,
    name: "my-team-add"
  }, {
    path: "/my-team/pending",
    component: _2afe31e6,
    name: "my-team-pending"
  }, {
    path: "/news/CreateNewsModal",
    component: _4b0d6d5c,
    name: "news-CreateNewsModal"
  }, {
    path: "/news/EditNewsModal",
    component: _e8fb3bf8,
    name: "news-EditNewsModal"
  }, {
    path: "/news/SeenByModal",
    component: _683b006f,
    name: "news-SeenByModal"
  }, {
    path: "/profiles/create",
    component: _7bc8067e,
    name: "profiles-create"
  }, {
    path: "/profiles/pending",
    component: _386a11bc,
    name: "profiles-pending"
  }, {
    path: "/profiles/update-mixin",
    component: _96174966,
    name: "profiles-update-mixin"
  }, {
    path: "/rejection-categories/new",
    component: _45722ca1,
    name: "rejection-categories-new"
  }, {
    path: "/triggers/manage",
    component: _754aca39,
    name: "triggers-manage"
  }, {
    path: "/triggers/pending",
    component: _77b07b73,
    name: "triggers-pending"
  }, {
    path: "/update-stats/AddNewFeature",
    component: _22ee0453,
    name: "update-stats-AddNewFeature"
  }, {
    path: "/account-requests/:request?",
    component: _393e5aca,
    name: "account-requests-request"
  }, {
    path: "/activity-logs/:type?",
    component: _a377ff92,
    name: "activity-logs-type"
  }, {
    path: "/conversations/:id?",
    component: _849fd30a,
    name: "conversations-id"
  }, {
    path: "/my-team/:operator?",
    component: _165c4b95,
    name: "my-team-operator"
  }, {
    path: "/profile-duplicates/:country?",
    component: _b5ecf1cc,
    name: "profile-duplicates-country"
  }, {
    path: "/profiles/:profile",
    component: _253e2532,
    name: "profiles-profile"
  }, {
    path: "/profiles/:profileold",
    component: _f41572f0,
    name: "profiles-profileold"
  }, {
    path: "/triggers/:trigger",
    component: _299b404d,
    name: "triggers-trigger"
  }, {
    path: "/my-team/:operator?/edit",
    component: _65e4ddd2,
    name: "my-team-operator-edit"
  }, {
    path: "/my-team/:operator?/lock-logs",
    component: _4eabfa58,
    name: "my-team-operator-lock-logs"
  }, {
    path: "/my-team/:operator?/message-list",
    component: _3b35bfb1,
    name: "my-team-operator-message-list"
  }, {
    path: "/my-team/:operator?/profile-list",
    component: _1c142762,
    name: "my-team-operator-profile-list"
  }, {
    path: "/my-team/:operator?/trigger-list",
    component: _61a44120,
    name: "my-team-operator-trigger-list"
  }, {
    path: "/profiles/:profile/photos",
    component: _3b6868d1,
    name: "profiles-profile-photos"
  }, {
    path: "/",
    component: _39647c52,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
